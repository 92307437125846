<template>
  <AppTitle>
    {{ topic?.taggings_count }} Artikel für <i>#{{ topic?.name }}</i>
  </AppTitle>
  <ArticlesCardList
    :articles="articles"
    :is-loading="isLoading"
    :total-pages="totalPages"
    :current-page="page"
    @fetchMore="fetchArticles(topic)"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import kebabCase from 'lodash/kebabCase';
import { StoryblokResult, StoryData } from 'storyblok-js-client';
import { ArticleModel } from '@/models/article.model';
import { RouteLocationNormalizedLoaded, Router, useRoute, useRouter } from 'vue-router';
import AppTitle from '@/components/ui/AppTitle.vue';
import { fetchArticlesByTopic, fetchTags } from '@/composables/api';
import { parseStoryblokResultToStoryData } from '@/composables/storyblokResultParser';
import { TopicModel } from '@/models/topic.model';
import ArticlesCardList from '@/components/ui/ArticlesCardList.vue';
import usePagination, { FetchFnReturn } from '@/composables/usePagination';

export default defineComponent({
  components: {
    AppTitle,
    ArticlesCardList,
  },
  setup() {
    const topic = ref<TopicModel>();
    const route: RouteLocationNormalizedLoaded = useRoute();
    const router: Router = useRouter();

    const fetchFn = async (page: number): Promise<FetchFnReturn<StoryData<ArticleModel>>> => {
      const topicName: string = (topic.value as TopicModel).name;

      const result: StoryblokResult = await fetchArticlesByTopic(topicName, page);

      return {
        items: parseStoryblokResultToStoryData(result),
        total: result.total,
        perPage: result.perPage,
      };
    };

    const { next: nextPage, isLoading, items: articles, page, totalPages } = usePagination(fetchFn);

    const fetchArticles = async (topic: TopicModel) => {
      if (!topic) {
        return;
      }

      await nextPage();
    };

    onMounted(async () => {
      const { data: tagsData }: StoryblokResult = await fetchTags();
      const selectedTopic: TopicModel = tagsData.tags.find((t: any) => kebabCase(t.name) === route.params.slug);

      if (selectedTopic) {
        topic.value = selectedTopic;
        await fetchArticles(selectedTopic);
      } else {
        router.push('/articles');
      }
    });

    return { topic, articles, isLoading, fetchArticles, totalPages, page };
  },
});
</script>
